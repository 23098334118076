.header-card{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-image: url("/images/header-card-bg.png");
    background-repeat: no-repeat;
    background-position: center, bottom;
    background-color:#142850;
    background-attachment: fixed;
    transition: 2s ease-in-out;
    height: 375px;
}
.mj-avatar{
    display: block;
    border-radius: 50%;
    background: #e5e5e5;
    box-shadow: 6px 8px 10px rgba(0, 0, 0, 0.2);
    height: 150px;
    width: 150px;  
    background-image: url("/images/manoj-1.jpg");              
    background-size: 160px;
    background-position: top;
    background-repeat: no-repeat;
}

.social-links{
    display: flex;
    flex-direction: row;
    width: 350px;
    margin: 50px 0px;
    justify-content: space-evenly;
    background-color: whitesmoke;
    padding: 5px;
    height: 50px;
    align-items: center;
    border-radius: 5px;
}

.cv-link{
    color: #333333;
    font-weight: 700;
    font-size: 22px;
}