.project-links{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 20px 0px;
}

.latest-project-title{
    color: #00ab25;
    font-size: 40px;
}