
.intro-card{
    .front-face-card-content{
        background-image: url("/images/intro-blob.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        h1{
            color: #fd5e53;
        }
    }
}

.skills-card{
    background-color: #c9fdd7;
    .more{
        background-color: #21bf73;
        box-shadow: 4px 4px 12px #21bf73;
        color: #f9fcfb;
        font-weight: 700;
    }
}

.education-card{
    background-color: #2f89fc;
    color: #f5f5f5;
    .more{
        box-shadow: none !important;
        color: #2f89fc;
        font-weight: 700;
    }
}

.experiences-card{
    .front-face-card{
        background-image: url("/images/projects-card-bg.png");
        background-position: right;
    }
    .more{
        background-color: #edffea;
        color: #ea6227;
        font-weight: 700;
        box-shadow: 4px 4px 12px #edffea;
    }
}

.hobbies-card{
    .hobbies-ff-text{
        min-height: 80px;
        margin: 0px;
        text-align: center;
        padding: 5px;
        h1{
            font-size: 25px;
        }
    }
    .front-face-card-content{
        background-image: url('/images/keyboard-asmr.gif');
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 280px !important;
    }
}


.contact-card{
    h1{
        margin: 0px;
    }
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22600%22%20height%3D%22600%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M407.3%20172.8c47.8%2039.4%20113.5%2058%20139.6%2099.4%2026.1%2041.4%2012.6%20105.6-18.7%20159.3-31.3%2053.7-80.3%2096.9-137.7%20118.3-57.4%2021.5-123.2%2021.1-173.6-4.5-50.4-25.7-85.4-76.8-117.2-130.4-31.9-53.6-60.6-109.8-54.9-163.1%205.6-53.2%2045.6-103.6%2093.8-143%2048.3-39.3%20104.8-67.5%20148-51.6%2043.2%2016%2073%2076.1%20120.7%20115.6z%22%20fill%3D%22%23dcd6f7%22%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 655px;
}

.contact-form{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    background-repeat: no-repeat;
    div{
        text-align: start;
        margin-bottom: 10px;
    }
    form{
        width: 100%;
        background: none;
        background-repeat: no-repeat;
    }
    input,textarea{
        font-weight: 500;
        border: none;
        border-radius: 3px;
        height: 35px;
        font-size: 16px;
        outline: none;
        width: 100% !important;
        padding: 10px;
    }
    textarea{
        min-height: 100px !important;
        max-height: 150px;
        height: 100px;
    }
    button{
        cursor: pointer;
        width: 150px;
        height: 32px;
        font-size: 15px;
        border-radius: 4px;
        font-family: 'Jost', sans-serif;
        background-color: #424874;
        color: #f4eeff;
        font-weight: 700;
        outline: none;
        border: none;
    }
}

.err-msg{
    text-align: center;
    color: #ff5200;
}
