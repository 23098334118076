.cards-container{
    margin: auto !important;
    position: relative !important;
    top: -75px;
}

.card{
    padding: 22px;
    height: 450px;
    width: 380px;
    min-width: 380px;
    min-height: 475px;
    display: flex;
    flex-direction: column;
    margin: 35px;
    border-radius: 25px;
    background: #ffffff;
    flex-wrap: nowrap;
}

.front-face-card{
    display: flex;
    flex-direction: column;
    height: 100%;
    .front-face-card-content{
        min-height: 365px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;
        font-weight: bold;
        scrollbar-color: red;
        h2{
            margin: 2px 0px;
        }
    }
}

.back-face-card{
    .back-face-card-content{
        overflow: auto !important;
        height: 365px;
        padding: 5px;
        margin-top: -20px;
        letter-spacing: 0.3px;
        font-size: 18px;
    }
    h1{
        margin-top: 0px;
        font-weight: 900;
        font-size: 33px;
    }
    h2{
        margin: -2px 0px;
        font-size: 23px;
    }
    p{
        margin: 15px 0px;
    }
}

.more{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-weight: 100;
    letter-spacing: 0.5px;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 4px 4px 9px #d9d9d9, -4px -4px 9px #ffffff;
    cursor: pointer;
    img{
        width: 20px;
        margin-left: 15px;
    }
}

a{
    color: #1089ff;
    text-decoration: none;
}

hr{
    color: #e5e5e5;
    width: 25%;
    margin: 25px auto;
}

ul{
    margin: 15px 0px;
    padding-left: 20px;
    li{
        list-style: disclosure-closed;
        margin: 6px 0px;
    }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.4);
}

@media only screen and (max-width: 600px){
    .card{
        width: 380px;
        min-width: 380px;
        margin-bottom: 10px;
    }
    .back-face-card h1{
        font-size: 33px;
    }
}




